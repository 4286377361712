import * as React from 'react'

const AudioComponent = ({ audioTitle, audioSubtitle, audioFragment }) => {
  return (
    <div className="audio">
      <h3>{audioTitle}</h3>
      <h4>{audioSubtitle}</h4>
      <audio controls>
        <source src={audioFragment} type="audio/mpeg" />
      </audio>
    </div>
  )
}

export default AudioComponent
import * as React from "react"
import { graphql } from "gatsby"
import ImageComponent from "../components/ImageComponent"
import "fontsource-source-sans-pro/300.css"
import "fontsource-source-sans-pro/400.css"
import "fontsource-source-sans-pro/400-italic.css"
import "fontsource-source-sans-pro/600.css"
import '../styles/main.scss'
import schubert from '../audio/schubert2020.wav'
import partita from '../audio/arvopartita.wav'
import AudioComponent from '../components/AudioComponent'
import SEO from '../components/seo'
import NavComponent from '../components/NavComponent'

const MediaPage = ({ data }) => {
  return (
    <>
      <SEO />
      <ImageComponent photo={data.photoMedia.childImageSharp.gatsbyImageData} page="media" />
      <NavComponent activePage='media' />
      <main>
        <AudioComponent audioTitle="Schubert Piano Sonate D.894, eerste deel" audioSubtitle="18 juni 2020" audioFragment={schubert} />
        <AudioComponent audioTitle="Pärt Partita" audioSubtitle="1 juli 2021" audioFragment={partita} />
      </main>
    </>
  )
}

export default MediaPage

export const query = graphql`{
  photoMedia: file(relativePath: {eq: "photo-media.jpeg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
  }
}
`